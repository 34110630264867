import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
// Material UI
import AddIcon from '@mui/icons-material/Add';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
// Components
import EditUserDialog from '../../account/users/dialogs/EditUserDialog';
import useApi from '../../hooks/useApi';
import useSnackbar from '../../hooks/useSnackbar';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import AuditingTable from '../auditing/AuditingTable';
import CampaignsTable from '../campaigns/CampaignsTable';
import DeleteAccountModal from '../shared/modals/DeleteAccountModal';
import AccountDetailsForm from './components/AccountDetailsForm';
import AccountInfo from './components/AccountInfo';
import ComparisonForm from './components/ComparisonForm';
import ContactsInfo from './components/ContactsInfo';
import ProductsList from './components/ProductsList';
import EmailAuthentication from './components/EmailAuthentication';
import SmtpSettings from './components/SmtpSettings';
import UsersList from './components/UsersList';
import AllowedRecipients from './components/AllowedRecipients';
import AddSafeSenderDialog from './modals/AddSafeSenderDialog';
import AddUserModal from './modals/AddUserModal';
import CancelAccountModal from './modals/CancelAccountModal';
import DeleteSafeSenderDialog from './modals/DeleteSafeSenderDialog';
import DeleteUserModal from './modals/DeleteUserModal';
import AddAllowedRecipientDialog from './modals/AddAllowedRecipientDialog';
import DeleteAllowedRecipientDialog from './modals/DeleteAllowedRecipientDialog';
import AddPacksModal from './modals/AddPacksModal';
import ToggleFeatureModal from './modals/ToggleFeatureModal';
import BrandManagement from '../brandManagement/BrandManagement';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Gauge from './charts/Gauge';
import UserChartDonut from './charts/UserChartDonut';
import SubAccounts from './components/SubAccounts';

const cellStyles = {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
};

const TabPanel = ({ value, index, children }) => {
    return (
        <div>
            {value === index && children}
        </div>
    );
};

const AccountDetails = ({ showHamburger, onClickHamburger }) => {
    const { id } = useParams();
    const history = useHistory();
    const location = useLocation();
    const { handleGet, handlePut, handlePost, handleDelete } = useApi();
    const [account, setAccount] = useState(null);
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [showToggleFeature, setShowToggleFeature] = useState(false);
    const [showAddPacksModal, setShowAddPacksModal] = useState(false);
    const [newUserEmail, setNewUserEmail] = useState('');
    const [newUserName, setNewUserName] = useState('');
    const [showDeleteAccountDialog, setShowDeleteAccountDialog] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [showDeleteUserDialog, setShowDeleteUserDialog] = useState(false);
    const [showCancelDialog, setShowCancelDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { showSnackbar } = useSnackbar();
    const [dialog, setDialog] = useState(null);
    const [tab, setTab] = useState(0);
    const theme = useTheme();

    const handleFetchAccount = async () => {
        setIsLoading(true);
        const response = await handleGet(`superadmin/accounts/${id}`);

        if (!response.ok) {
            showSnackbar('An error occurred.', 'error');
            setIsLoading(false);
            return;
        }

        const data = await response.json();

        setAccount(data);
        setIsLoading(false);
    };

    const handleLoadAccounts = () => {
        history.push({
            pathname: '/superadmin/accounts',
            state: location.state
        });
    };

    const handleSaveAccount = async account => {
        setIsLoading(true);

        const response = await handlePut(`superadmin/accounts/${id}`, {
            company: account.company,
            customerName: account.customerName,
            emailAddress: account.emailAddress
        });

        if (!response.ok) {
            showSnackbar('An error occurred.', 'error');
            setIsLoading(false);
            return;
        }

        showSnackbar('Details saved', 'success');

        await handleFetchAccount();
    };

    // Add user modal functions

    const handleShowAddUserDialog = () => {
        if (!account.expired) {
            setShowAddUserModal(true);
        }
    };

    const handleToggleFeatures = async (user) => {
        try {
            setSelectedUser(user);
            setShowToggleFeature(true);
        }
        catch (error) {
            showSnackbar('An error occurred.', 'error');
            setIsLoading(false);
        }
    };

    const handleHideToggleFeatures = () => {
        setShowToggleFeature(false);
        setSelectedUser(null);
    };

    const handleShowAddPacksDialog = () => {
        if (!account.expired) {
            setShowAddPacksModal(true);
        }
    };

    const handleHideAddPacksDialog = () => {
        setShowAddPacksModal(false);
    };

    const handleHideAddUserDialog = () => {
        setShowAddUserModal(false);
        setNewUserEmail('');
        setNewUserName('');
    };

    const handleAddUser = async permissions => {
        setIsLoading(true);

        const uri = `superadmin/accounts/${id}/users`;

        const response = await handlePost(uri, { emailAddress: newUserEmail, name: newUserName, permissions });

        setIsLoading(false);

        if (!response.ok) {
            try {
                const errorMessage = await response.json();

                if (errorMessage.message) {
                    showSnackbar(`${errorMessage.message}`, 'error');
                }
                else {
                    showSnackbar('An error occurred.', 'error');
                }
            }
            catch (error) {
                showSnackbar('An error occurred.', 'error');
            }
        }

        await handleFetchAccount();

        setNewUserEmail('');
        setNewUserName('');
    };

    // Toggle feature modal functions
    const handleToggleFeaturesSubmit = async (data) => {
        setIsLoading(true);
        const url = `superadmin/accounts/${id}/users/${selectedUser.id}`;

        selectedUser.userFeatures = data;

        const response = await handlePut(url, selectedUser);

        if (response.ok) {
            showSnackbar('Feature added', 'success');
            await handleFetchAccount();
        }
        else {
            showSnackbar('An error occurred.', 'error');
        }

        setIsLoading(false);
    };

    // Delete user modal functions

    const handleShowDeleteUserDialog = user => {
        setSelectedUser(user);
        setShowDeleteUserDialog(true);
    };

    const handleHideDeleteUserDialog = () => {
        setShowDeleteUserDialog(false);
        setSelectedUser(null);
    };

    const handleDeleteUser = async () => {
        const url = `superadmin/accounts/${id}/users/${selectedUser.id}`;

        setIsLoading(true);
        handleHideDeleteUserDialog();

        await handleDelete(url);

        handleFetchAccount();
    };

    // Cancel account modal functions

    const handleShowCancelDialog = () => {
        setShowCancelDialog(true);
    };

    const handleHideCancelDialog = () => {
        setShowCancelDialog(false);
    };

    const handleCancelAccount = async () => {
        setIsLoading(true);
        handleHideCancelDialog();

        await handlePut(`superadmin/accounts/${id}`, {
            cancelled: true
        });

        await handleFetchAccount();
    };

    // Delete account modal functions

    const handleShowDeleteAccountModal = () => {
        setShowDeleteAccountDialog(true);
    };

    const handleHideDeleteAccountModal = () => {
        setShowDeleteAccountDialog(false);
    };

    const handleDeleteAccount = async () => {
        setIsLoading(true);
        handleHideDeleteAccountModal();

        await handleDelete(`superadmin/accounts/${id}`);
        handleLoadAccounts();
    };

    // edit user

    const handleEditUser = async user => {
        setIsLoading(true);
        setDialog(null);

        await handlePut(`superadmin/accounts/${id}/users/${user.id}`, user);

        handleFetchAccount();
    };

    const handleOpenEditUserDialog = user => {
        setDialog(
            <EditUserDialog
                user={user}
                onSubmit={({ permissions, name }) => handleEditUser({ id: user.id, permissions, name })}
                onClose={() => setDialog(null)}
            />
        );
    };

    // safe senders

    const handleAddSafeSender = async emailAddress => {
        setIsLoading(true);
        setDialog(null);

        await handlePut(`superadmin/accounts/${id}`, {
            safeSenders: [...account.safeSenders.filter(e => e !== emailAddress), emailAddress]
        });

        handleFetchAccount();
    };

    const handleOpenAddSafeSenderDialog = () => {
        setDialog(
            <AddSafeSenderDialog
                onSubmit={handleAddSafeSender}
                onCancel={() => setDialog(null)}
            />
        );
    };

    const handleDeleteSafeSender = async emailAddress => {
        setIsLoading(true);
        setDialog(null);

        await handlePut(`superadmin/accounts/${id}`, {
            safeSenders: [...account.safeSenders.filter(e => e !== emailAddress)]
        });

        handleFetchAccount();
    };

    const handleOpenDeleteSafeSenderDialog = emailAddress => {
        setDialog(
            <DeleteSafeSenderDialog
                emailAddress={emailAddress}
                customerId={account.id}
                onSubmit={() => handleDeleteSafeSender(emailAddress)}
                onCancel={() => setDialog(null)}
            />
        );
    };

    // allowed recipients

    const handleAddAllowedRecipient = async (pattern, type) => {
        setIsLoading(true);
        setDialog(null);

        await handlePut(`superadmin/accounts/${id}`, {
            allowedRecipients: [...account.allowedRecipients.filter(e => e.pattern !== pattern).map(e => ({ pattern: e.pattern, type: e.type })), { pattern, type }]
        });

        handleFetchAccount();
    };

    const handleOpenAddAllowedRecipientDialog = () => {
        setDialog(
            <AddAllowedRecipientDialog
                onSubmit={handleAddAllowedRecipient}
                onCancel={() => setDialog(null)}
            />
        );
    };

    const handleDeleteAllowedRecipient = async recipientId => {
        setIsLoading(true);
        setDialog(null);

        await handlePut(`superadmin/accounts/${id}`, {
            allowedRecipients: [...account.allowedRecipients.filter(e => e.id !== recipientId).map(e => ({ pattern: e.pattern, type: e.type }))]
        });

        handleFetchAccount();
    };

    const handleOpenDeleteAllowedRecipientDialog = recipient => {
        setDialog(
            <DeleteAllowedRecipientDialog
                pattern={recipient.pattern}
                customerId={account.id}
                onSubmit={() => handleDeleteAllowedRecipient(recipient.id)}
                onCancel={() => setDialog(null)}
            />
        );
    };

    useEffect(() => {
        handleFetchAccount();
    }, [id]);

    if (!account) {
        return (
            <LoadingOverlay />
        );
    }

    return (
        <>
            {isLoading && <LoadingOverlay />}
            <AppBar position="sticky" color="inherit" sx={{ top: 64, zIndex: theme.zIndex.appBar + 1 }}>
                <Toolbar>
                    {showHamburger && (
                        <IconButton
                            edge="start"
                            color="inherit"
                            sx={{ mr: 2 }}
                            onClick={() => onClickHamburger(true)}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                    <Breadcrumbs>
                        <Link href="/#/superadmin">Super Admin</Link>
                        <Link href="/#/superadmin/accounts">Accounts</Link>
                        <Typography>
                            <strong>CID: {id}</strong>
                        </Typography>
                    </Breadcrumbs>
                </Toolbar>
                <Tabs value={tab} onChange={(e, newValue) => setTab(newValue)}>
                    <Tab label="Summary" />
                    <Tab label="Users" />
                    <Tab label="Products" />
                    <Tab label="Campaigns" />
                    <Tab label="Configuration" />
                    <Tab label="Audit" />
                    <Tab label="Authentication" />
                    <Tab label="Brand Management" />
                </Tabs>
            </AppBar>
            <AccountInfo
                account={account}
                onCancelAccount={handleShowCancelDialog}
                onDeleteAccount={handleShowDeleteAccountModal}
            />
            <TabPanel value={tab} index={0}>
                <Card sx={{ m: 2, mb: 0 }}>
                    <AccountDetailsForm
                        account={account}
                        onChangeAccount={handleSaveAccount}
                        isLoading={isLoading}
                    />
                </Card>
                {account.subAccountList && account.subAccountList.length > 0 && (
                    <Card sx={{ m: 2, mb: 0 }}>
                        <SubAccounts account={account} />
                    </Card>
                )}
                <Grid container spacing={2} sx={{ p: 2, pb: 0 }} direction="row">
                    <Grid item xs={12} md={4} sx={{ gridRow: 'span 2' }}>
                        <Card sx={{ height: '100%' }}>
                            <ContactsInfo account={account} />
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card sx={{ height: '100%' }}>
                            <UserChartDonut account={account} />
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card sx={{ height: '100%' }}>
                            <Gauge account={account} title="Credits Available" label="credits" used={account.credits.usedQuota} total={account.credits.totalQuota} />
                        </Card>
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12} lg={6}>
                        <Card sx={{ height: '100%' }}>
                            <Toolbar variant="dense" disableGutters sx={{ px: 2 }}>
                                <Typography variant="subtitle2">Campaigns Sent</Typography>
                            </Toolbar>
                            <ComparisonForm account={account} urlString="superadmin/campaigns/count" />
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Card sx={{ height: '100%' }}>
                            <Toolbar variant="dense" disableGutters sx={{ px: 2 }}>
                                <Typography variant="subtitle2">Emails Sent</Typography>
                            </Toolbar>
                            <ComparisonForm account={account} urlString="superadmin/accounts/emails/count" />
                        </Card>
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <Card sx={{ m: 2 }}>
                    <UsersList
                        users={account.users}
                        userLimit={account.userLimit}
                        onAdd={handleShowAddUserDialog}
                        onEdit={handleOpenEditUserDialog}
                        onDelete={handleShowDeleteUserDialog}
                        toggleFeature={handleToggleFeatures}
                    />
                </Card>
                <Card sx={{ m: 2 }}>
                    <UserChartDonut account={account} />
                </Card>
            </TabPanel>
            <TabPanel value={tab} index={2}>
                <Card sx={{ m: 2 }}>
                    <ProductsList title="Licences" products={account.licences} customerId={account.id} onRefresh={handleFetchAccount} />
                </Card>
                <Card sx={{ m: 2 }}>
                    <ProductsList title="Packs" products={account.packs} customerId={account.id} onRefresh={handleFetchAccount} onAddPack={handleShowAddPacksDialog} />
                </Card>
            </TabPanel>
            <TabPanel value={tab} index={3}>
                <Card sx={{ m: 2 }}>
                    <Toolbar variant="dense" disableGutters sx={{ px: 2 }}>
                        <Typography variant="subtitle2">Campaigns</Typography>
                    </Toolbar>
                    <CampaignsTable cid={account.id} rowsOption={1} useLoadingBox={true} />
                </Card>
            </TabPanel>
            <TabPanel value={tab} index={4}>
                <Card sx={{ m: 2, mb: 0 }}>
                    <SmtpSettings account={account} onRefresh={handleFetchAccount} />
                </Card>
                <Card sx={{ m: 2, mb: 0 }}>
                    <Toolbar variant="dense" disableGutters sx={{ px: 2 }}>
                        <div style={{ flexGrow: 1 }}>
                            <Typography variant="subtitle2">Safe Senders</Typography>
                        </div>
                        <Button size="small" onClick={handleOpenAddSafeSenderDialog} startIcon={<AddIcon />}>
                            Add Safe Sender
                        </Button>
                    </Toolbar>
                    <Divider />
                    {account.safeSenders && account.safeSenders.length > 0 ? (
                        <TableContainer>
                            <Table size="small">
                                <TableBody>
                                    {account.safeSenders.map(emailAddress => (
                                        <TableRow key={emailAddress}>
                                            <TableCell sx={cellStyles}>{emailAddress}</TableCell>
                                            <TableCell sx={cellStyles} align="right">
                                                <Link
                                                    onClick={() => handleOpenDeleteSafeSenderDialog(emailAddress)}
                                                    title="Delete Safe Sender"
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    Delete
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <Box sx={{ p: 2 }}>
                            <Typography variant="body2">This account has no safe senders set up.</Typography>
                        </Box>
                    )}
                </Card>
                {account.isInternalComms && (
                    <Card sx={{ m: 2 }}>
                        <AllowedRecipients
                            onAdd={handleOpenAddAllowedRecipientDialog}
                            onDelete={handleOpenDeleteAllowedRecipientDialog}
                            allowedRecipients={account.allowedRecipients}
                            cellStyles={cellStyles}
                        />
                    </Card>
                )}
            </TabPanel>
            <TabPanel value={tab} index={5}>
                <Card sx={{ m: 2 }}>
                    <Toolbar variant="dense" disableGutters sx={{ px: 2 }}>
                        <Typography variant="subtitle2">Auditing</Typography>
                    </Toolbar>
                    <AuditingTable cid={account.id} rowsOption={0} useLoadingBox={true} />
                </Card>
            </TabPanel>
            <TabPanel value={tab} index={6}>
                <Box sx={{ my: 2 }}>
                    <EmailAuthentication />
                </Box>
            </TabPanel>
            <TabPanel value={tab} index={7}>
                <Box sx={{ my: 2 }}>
                    <BrandManagement account={account} onRefresh={handleFetchAccount} />
                </Box>
            </TabPanel>

            {showCancelDialog && (
                <CancelAccountModal
                    shown={showCancelDialog}
                    account={account}
                    onHide={handleHideCancelDialog}
                    onConfirm={handleCancelAccount}
                />
            )}

            {showAddUserModal && (
                <AddUserModal
                    shown={showAddUserModal}
                    email={newUserEmail}
                    name={newUserName}
                    handleChange={(e) => setNewUserEmail(e.target.value)}
                    handleNameChange={(e) => setNewUserName(e.target.value)}
                    handleHide={handleHideAddUserDialog}
                    onSubmit={handleAddUser}
                    isLoading={isLoading}
                />
            )}

            {showDeleteUserDialog && (
                <DeleteUserModal
                    shown={showDeleteUserDialog}
                    user={selectedUser}
                    onHide={handleHideDeleteUserDialog}
                    onConfirm={handleDeleteUser}
                    accountId={account.id}
                />
            )}

            {showDeleteAccountDialog && (
                <DeleteAccountModal
                    shown={showDeleteAccountDialog}
                    account={account}
                    onHide={handleHideDeleteAccountModal}
                    onConfirm={handleDeleteAccount}
                />
            )}

            {showAddPacksModal && (
                <AddPacksModal
                    shown={showAddPacksModal}
                    onHide={handleHideAddPacksDialog}
                    onRefresh={handleFetchAccount}
                    customerId={account.id}
                />
            )}

            {showToggleFeature && (
                <ToggleFeatureModal
                    shown={showToggleFeature}
                    onHide={handleHideToggleFeatures}
                    isLoading={isLoading}
                    onSubmit={handleToggleFeaturesSubmit}
                    data={selectedUser}
                />
            )}

            {dialog}
        </>
    );
};

export default AccountDetails;
